@import "flatly/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.card {
  box-shadow: $box-shadow-sm;
}

.fa-glyphicon {
  font-size: 2em;
}

@include media-breakpoint-up(md) {
  .fa-glyphicon {
    font-size: 1.33em;
  }
}

@include media-breakpoint-down(xs) {
  .nav-link {
    font-size: 20px;
  }
}

@import "~bootstrap/scss/bootstrap";
@import "flatly/bootswatch";

body {
  background-color: $light;
}

.CircularProgressbar {
  width: 85px;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $primary;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: $light;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: $primary;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: $light;
}
