.fa-chevron-right {
  transition: .2s transform ease-in-out;
}

button > * {
  pointer-events: none;
}

div [href] {
    cursor: pointer;
}